import i18n from '@/i18n/index'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'hotel_id',
      name: 'select',
      options: [],
      label: i18n.t('form.hotel.hotelName'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.hotel.hotelName') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.hotel.roomName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.hotel.roomName') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'short_description',
      name: 'input',
      label: i18n.t('form.common.shortDescription'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.common.shortDescription') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      value: [],
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 100,
        imageName: 'remote_image_url'
      }
    }]
  }, {
    title: i18n.t('form.attractions.specialLabel'),
    name: 1,
    createDateItem: [{
      span: 24,
      prop: 'labels_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.attractions.label'),
      Object: {
        delete: '_destroy'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'name',
        name: 'input',
        label: i18n.t('form.common.cnName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
        }
      }, {
        span: 12,
        prop: 'name_en',
        name: 'input',
        label: i18n.t('form.common.enName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
        }
      }]
    }]
  }]
}
